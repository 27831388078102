<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0">
        {{ t('customer.label', 2) }}
      </h1>
      <div>
        <button
          class="small mr-2"
          @click="openModal({
            name: '',
            number: '',
            description: '',
            customerGroup: null,
            customerIdentifiers: [],
            balance: { currency: 'CHF', amount: 0 },
            sort: 500,
          }, 'editCustomer')"
        >
          +
        </button>
        <button
          class="white small mr-2"
          @click="openModal({
            customerGroup: null,
            balance: { amount: 0, currency: 'CHF' },
            amount: 1,
            identifiers: [10],
            rangeStart: 0,
          }, 'batchCustomer')"
        >
          {{ $t('general.action.createMultiple') }}
        </button>
        <button
          class="white small mr-2"
          @click="openModal({
            customerGroup: null,
            type: 10,
            content: null,
          }, 'updateCustomerIdentifiers')"
        >
          {{ $t('customerIdentifier.updateTitle') }}
        </button>
        <button
          class="white small"
          @click="openModal({
            customerGroup: null,
            type: 10,
            content: null,
          }, 'removeCustomerIdentifiers')"
        >
          {{ $t('customerIdentifier.removeTitle') }}
        </button>
      </div>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      :filters="filters"
      @update:filters="updateFilters"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
      @click:edit="openModal(normalizeEntity($event), 'editCustomer')"
      @click:delete="openModal($event, 'deleteCustomer')"
    />

    <edit-customer-modal @update="loadEntities" />

    <customer-identifier-batch-update-modal @update="loadEntities" />

    <customer-identifier-batch-remove-modal @update="loadEntities" />

    <delete-modal
      type="deleteCustomer"
      entity-translation-key="customer"
      label="name"
      @update="loadEntities"
    />

    <create-customer-batch-modal
      :customer-group="entity['@id']"
      @update="loadEntities"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from '@/services/axios';
import useDataModal from '@/hooks/dataModal';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditCustomerModal from '@/components/entities/customer/EditCustomerModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import CreateCustomerBatchModal from '@/components/entities/customer/CreateCustomerBatchModal.vue';
import CustomerIdentifierBatchUpdateModal from '@/components/entities/customer/CustomerIdentifierBatchUpdateModal.vue';
import CustomerIdentifierBatchRemoveModal from '@/components/entities/customer/CustomerIdentifierBatchRemoveModal.vue';

export default {
  name: 'CustomerIndexPage',
  components: {
    CustomerIdentifierBatchRemoveModal,
    CustomerIdentifierBatchUpdateModal,
    DeleteModal,
    EditCustomerModal,
    EntityTable,
    CreateCustomerBatchModal,
  },
  setup() {
    const { t } = useI18n();
    const entity = ref({});
    const normalizeEntity = (e) => ({
      ...e,
      customerGroup: e.customerGroup?.['@id'],
      // balance: e?.balance?.['@id'],
    });
    const { customer: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      filters,
      updateFilters,
      resetFilters,
    } = useLoadEntities(
      '/customers',
      { fields, sort: 'number' },
    );

    const store = useStore();
    const openModal = (e, modal) => {
      store.dispatch('modals/openModal', { modal, entity: e });
    };

    const {
      modal: batchModal,
      openModal: openBatchModal,
    } = useDataModal(entity);

    const {
      entities: groups,
    } = useLoadEntities('/customer_groups', { itemsPerPage: 999 });

    fields.value.find((f) => f.key === 'customerGroup').searchFilter = {
      type: 'choice',
      options: {
        choices: groups,
      },
    };

    return {
      t,
      entity,
      normalizeEntity,
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      filters,
      loadEntities,
      updateFilters,
      resetFilters,
      openModal,
      batchModal,
      openBatchModal,
    };
  },
};
</script>
