<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="removeCustomerIdentifiers"
    :add-save-label="false"
    :options="{
      properties: {
        customerGroup: null,
        type: 10,
        content: null,
      },
      postUri: '/customer_identifiers/batch_remove',
      label: $t('customerIdentifier.removeTitle'),
    }"
    @update="$emit('update')"
  >
    <multiselect
      v-model="properties.customerGroup"
      :options="async (q) => loadCustomerGroupOptions(q)"
      :loading="loadingCustomerGroupOptions"
      :filter-results="false"
      :form-label="$t('customerGroup.label')"
      :error="errors.customerGroup"
      searchable
      required
    />

    <simple-dropdown v-model="properties.type" :options="identifierOptions" :label="$t('customerIdentifier.type.label')" />

    <div class="dropinput">
      <input id="file" type="file" @change="(ev) => drop(ev, properties)">
      <label
        ref="dropRef"
        for="file"
        class="droparea"
        @dragenter.prevent.stop="dragenter"
        @dragleave.prevent.stop="dragleave"
        @dragover.prevent.stop="dragover"
        @drop="(ev) => drop(ev, properties)"
      >
        <span v-if="numberOfIdentifiers === 0">{{ $t('general.action.dropFile') }}</span>
        <span v-else>{{ $t('customerIdentifier.xIdentifierFound', [ numberOfIdentifiers ]) }}</span>
      </label>
    </div>

  </modal-form>
</template>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import CustomerBatchForm from '@/components/entities/customer/CustomerBatchForm.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

export default {
  name: 'CustomerIdentifierBatchRemoveModal',
  components: {
    SimpleDropdown,
    CustomerBatchForm,
    Multiselect,
    ModalForm,
  },
  emits: ['update'],
  setup() {
    const store = useStore();

    const {
      loading: loadingCustomerGroupOptions,
      loadOptions: loadCustomerGroupOptions,
    } = useLoadOptions('/customer_groups', 'name');

    const identifierOptions = ref([
      { key: '10', label: 'QR-Codes', value: 10 },
      { key: '20', label: 'DormaKaba', value: 20 },
    ]);

    const dropRef = ref(null);
    const numberOfIdentifiers = ref(0);

    const dragenter = () => {
      dropRef.value.style.borderColor = 'var(--color-primary)';
    };

    const dragleave = () => {
      dropRef.value.style.borderColor = '';
    };

    const dragover = (ev) => {
      ev.preventDefault();
    };

    const drop = async (ev, properties) => {
      ev.preventDefault();
      let files = [];
      if (ev.dataTransfer) {
        const { dataTransfer } = ev;
        files = dataTransfer.files;
      } else if (ev.target.files) {
        files = ev.target.files;
      }
      if (files.length > 0) {
        const content = await files[0].text();
        numberOfIdentifiers.value = content.split('\n').filter((line) => line !== '').length;
        properties.content = content;
      }
    };

    return {
      loadCustomerGroupOptions,
      loadingCustomerGroupOptions,
      identifierOptions,
      numberOfIdentifiers,
      dropRef,
      dragenter,
      dragleave,
      dragover,
      drop,
    };
  },
};
</script>
