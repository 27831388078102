<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('customerGroup.label')" />
      <button
        class="small"
        @click="openEditModal({
          name: '',
          number: '',
          description: '',
          customers: [],
          promotions: [],
        })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      :filters="filters"
      @update:filters="updateFilters"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
      @click:delete="openDeleteModal($event)"
      @click:edit="openEditModal(normalizeEntity($event))"
      @click:qr="openExportModal($event)"
    />

    <edit-customer-group-modal @update="loadEntities" />
    <export-customer-group-modal />
    <delete-modal
      type="deleteCustomerGroup"
      entity-translation-key="customerGroup"
      label="name"
      @update="loadEntities"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { saveAs } from 'file-saver';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditCustomerGroupModal from '@/components/entities/customer_group/EditCustomerGroupModal.vue';
import CreateCustomerBatchModal from '@/components/entities/customer/CreateCustomerBatchModal.vue';
import axios from '@/services/axios';
import ExportCustomerGroupModal from '@/components/entities/customer_group/ExportCustomerGroupModal.vue';

export default {
  name: 'CustomerGroupIndexPage',
  components: {
    ExportCustomerGroupModal,
    CreateCustomerBatchModal,
    EditCustomerGroupModal,
    DeleteModal,
    EntityTable,
  },
  setup() {
    const store = useStore();
    const normalizeEntity = (entity) => ({
      ...entity,
      customers: entity?.customers?.map((c) => c['@id']) ?? [],
      promotions: entity?.promotions?.map((p) => p['@id']) ?? [],
      enabledStores: entity?.enabledStores?.map((p) => p['@id']) ?? [],
    });
    const { customerGroup: fields } = useEntityFields();
    const {
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      updateFilters,
    } = useLoadEntities('/customer_groups', { fields, sort: 'number' });

    const openEditModal = (e) => {
      store.dispatch('modals/openModal', { modal: 'editCustomerGroup', entity: e });
    };
    const openDeleteModal = (e) => {
      store.dispatch('modals/openModal', { modal: 'deleteCustomerGroup', entity: e });
    };
    const openExportModal = (e) => {
      store.dispatch('modals/openModal', { modal: 'exportQrCustomerGroup', entity: e });
    };
    const qrExport = (e) => {
      axios.get(`${window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL}${e['@id']}/export?limit=100`, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, `qrCodes_${e['@id']}.pdf`);
      });
    };

    return {
      fields,
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      updateFilters,
      normalizeEntity,
      openDeleteModal,
      openEditModal,
      qrExport,
      openExportModal,
    };
  },
};
</script>
