<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <entity-card
        :entity="entity"
        :title="entity.name"
        :fields="fields"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="openDeleteModal(entity)"
              v-text="t('general.action.delete')"
            />
            <button class="small" @click="openEditModal(normalizedEntity)" v-text="t('general.action.edit')" />
          </div>
        </template>

        <template #customerIdentifiers>
          <div v-if="entity.customerIdentifiers.length" class="-mx-8 overflow-x-auto">
            <table>
              <thead>
                <tr>
                  <th v-t="'customerIdentifier.type.label'" scope="col" />
                  <th v-t="'customerIdentifier.identifier.label'" scope="col" />
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="identifier in entity.customerIdentifiers"
                  :key="identifier['@id']"
                >
                  <td v-t="'customerIdentifier.type.' + identifier.type" />
                  <td>
                    <pre v-text="identifier.identifier" />
                  </td>
                  <td>
                    <font-awesome-icon
                      v-if="identifier.type === CUSTOMER_IDENTIFIER_TYPES.QR"
                      icon="qrcode"
                      class="icon"
                      @click="$store.dispatch('modals/openModal', { modal: 'customerIdentifierQr', entity: identifier })"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <span v-else v-t="'general.none'" />
        </template>
      </entity-card>
      <div class="card">
        <h2>{{ $t('customer.orderList') }} ({{ orderTotalItems }})</h2>
        <div v-if="orderEntities.length" class="">
          <entity-table
            v-model:sortBy="orderSortBy"
            v-model:sortDirection="orderSortDirection"
            :fields="orderDisplayFields"
            :entities="orderEntities"
            :loading="orderLoadingEntities"
            :current-page="orderCurrentPage"
            :total-items="orderTotalItems"
            :filters="orderFilters"
            @update:filters="orderUpdateFilters"
            @update:sort="orderLoadEntities()"
            @update:page="orderCurrentPage = $event; orderLoadEntities()"
          >
            <template #cell(balanceUsed)="data">
              {{ computeBalanceUsedForOrder(data) }}
            </template>
            <template #cell(balanceRecharged)="data">
              {{ computeBalanceRechargedInOrder(data) }}
            </template>
          </entity-table>
        </div>
        <span v-else v-t="'general.none'" />
      </div>

      <edit-customer-modal @update="loadData" />
      <customer-identifier-qr-modal />
      <delete-modal
        type="deleteCustomer"
        entity-translation-key="customer"
        @update="$router.push('/customers')"
      />
    </template>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import EditCustomerModal from '@/components/entities/customer/EditCustomerModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import formatPrice from '@/utils/formatPrice';
import { CUSTOMER_IDENTIFIER_TYPES } from '@/constants';
import CustomerIdentifierQrModal from '@/components/entities/customer/CustomerIdentifierQrModal.vue';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import { formatDate } from '@/utils/date-utils';
import getIdFromIri from '@/utils/getIdFromIri';

export default {
  name: 'CustomerDetailPage',
  components: {
    EntityTable,
    CustomerIdentifierQrModal,
    DeleteModal,
    EditCustomerModal,
    Spinner,
    EntityCard,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const entity = ref(null);
    const identifiers = ref([]);
    const normalizedEntity = computed(() => ({
      ...entity.value,
      customerGroup: entity.value?.customerGroup?.['@id'],
      balance: entity?.value?.balance,
    }));
    const fields = ref([
      {
        key: 'number',
        label: t('customer.number.label'),
      },
      {
        key: 'name',
        label: t('general.field.name'),
      },
      {
        key: 'customerGroup.name',
        label: t('customerGroup.label'),
      },
      {
        key: 'description',
        label: t('general.field.description'),
      },
      {
        key: 'balance',
        label: t('customer.balance.label'),
        formatter: formatPrice,
      },
      {
        key: 'customerIdentifiers',
        label: t('customerIdentifier.label', 2),
        colspan: true,
      },
    ]);

    const loadData = async () => {
      loading.value = true;

      try {
        const { data } = await axios.get(`/customers/${route.params.id}`);
        entity.value = data;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    const store = useStore();
    const openEditModal = (e) => {
      store.dispatch('modals/openModal', {
        entity: e,
        modal: 'editCustomer',
      });
    };
    const openDeleteModal = (e) => {
      store.dispatch('modals/openModal', {
        entity: e,
        modal: 'deleteCustomer',
      });
    };

    loadData();

    const orderApiFields = ref([
      {
        key: 'customer',
        searchFilter: {
          type: 'freeText',
          default: route.params.id,
        },
      },
    ]);

    const router = useRouter();
    const orderDisplayFields = ref([
      {
        key: 'orderTime',
        label: t('order.orderTime'),
        displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss'),
      },
      {
        key: 'total',
        label: t('order.total'),
        displayFilter: (v) => formatPrice(v),
      },
      {
        key: 'invoiceNumber',
        label: t('order.invoiceNumber'),
      },
      {
        key: 'orderCalloutNumber',
        label: t('order.orderCalloutNumber'),
      },
      {
        key: 'register.store.name',
        label: t('store.label'),
      },
      {
        key: 'balanceUsed',
        label: t('customer.orders.balanceUsed'),
      },
      {
        key: 'balanceRecharged',
        label: t('customer.orders.balanceRecharged'),
      },
      {
        key: '_actions',
        actions: ['detail'],
        actionOptions: (e) => {
          if (e && e.shift && e.register && e.register.store) {
            return {
              detail: router.resolve({
                name: 'store-shifts-orders-order-id',
                params: {
                  orderId: getIdFromIri(e['@id']),
                  shiftId: getIdFromIri(e.shift['@id']),
                  storeId: getIdFromIri(e.register.store['@id']),
                },
              }),
            };
          }
          return null;
        },
      },
    ]);
    const {
      entities: orderEntities,
      loadingEntities: orderLoadingEntities,
      currentPage: orderCurrentPage,
      totalItems: orderTotalItems,
      sortBy: orderSortBy,
      sortDirection: orderSortDirection,
      loadEntities: orderLoadEntities,
      filters: orderFilters,
      updateFilters: orderUpdateFilters,
      resetFilters: orderResetFilters,
    } = useLoadEntities(
      '/orders',
      {
        fields: orderApiFields,
        sort: 'orderTime',
        direction: 'desc',
        hideDeleted: false,
        defaultParams: {
          customer: route.params.id,
        },
      },
    );

    const computeBalanceUsedForOrder = (order) => {
      // TODO: Support for multiple currencies
      let currency = 'CHF';
      let totalUsed = 0;
      order.entity.orderPayments.forEach((payment) => {
        if (payment.paymentMethod === 30) {
          totalUsed += payment.paidAmount.amount;
          currency = payment.paidAmount.currency;
        }
      });
      return formatPrice({
        amount: totalUsed,
        currency,
      });
    };

    const computeBalanceRechargedInOrder = (order) => {
      // TODO: Support for multiple currencies
      let currency = 'CHF';
      let totalRecharged = 0;
      order.entity.lineItems.forEach((lineItem) => {
        if (lineItem.type === 50) {
          totalRecharged += lineItem.totalPrice.amount;
          currency = lineItem.totalPrice.currency;
        }
      });
      return formatPrice({
        amount: totalRecharged,
        currency,
      });
    };

    return {
      t,
      entity,
      normalizedEntity,
      identifiers,
      fields,
      loading,
      loadData,
      openEditModal,
      openDeleteModal,
      CUSTOMER_IDENTIFIER_TYPES,
      orderEntities,
      orderLoadingEntities,
      orderCurrentPage,
      orderTotalItems,
      orderSortBy,
      orderSortDirection,
      orderLoadEntities,
      orderFilters,
      orderUpdateFilters,
      orderResetFilters,
      orderDisplayFields,
      computeBalanceUsedForOrder,
      computeBalanceRechargedInOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
th:first-of-type,
td:first-of-type {
  padding-left: 2rem;
}

th:last-of-type,
td:last-of-type {
  padding-right: 2rem;
}
</style>
