<template>
  <div class="customer-batch-form">
    <fancy-input
      v-model="model.rangeStart"
      type="number"
      :label="$t('customer.number.start')"
      :min="0"
      :step="1"
    />
    <fancy-input
      v-model="model.amount"
      type="number"
      :label="$t('general.field.amount')"
      :min="0"
      :step="1"
    />
    <price-form v-model="model.balance" :label="$t('customer.balance.label')" />
    <simple-dropdown v-model="model.identifiers" :options="identifierOptions" :label="$t('customerIdentifier.label')" />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import PriceForm from '@/components/shared/forms/PriceForm.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

export default {
  name: 'CustomerBatchForm',
  components: {
    SimpleDropdown,
    FancyInput,
    PriceForm,
  },
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = computed(() => props.modelValue);

    watch(() => cloneDeep(model), (value) => {
      emit('update:modelValue', value);
    });

    const identifierOptions = ref([
      { key: '10', label: 'QR-Codes', value: [10] },
      { key: '20', label: 'DormaKaba', value: [20] },
    ]);

    return {
      model,
      identifierOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.customer-batch-form > *:not(:first-child) {
  @apply mt-4;
}
</style>
