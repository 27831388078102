<template>
  <modal :open="state.open" @close="closeModal">
    <h2 class="mt-0">
      {{ $t('customerGroup.action.export') }}
    </h2>
    <hr>
    <form @submit.prevent="startExport">
      <simple-dropdown v-model="exportType" :options="exportTypes" :label="$t('customerGroup.export.type.label')" />
      <fancy-input
        v-if="[CUSTOMER_GROUP_EXPORT_TYPES.TYPE_QR].includes(exportType)"
        v-model="customCss"
        :textarea="true"
        rows="20"
        :label="$t('customerGroup.export.customCss.label')"
        :error="errors.customCss"
      />
      <fancy-input v-model="minNumber" :label="$t('customerGroup.export.minNumber.label')" :error="errors.start" required />
      <fancy-input v-model="maxNumber" :label="$t('customerGroup.export.maxNumber.label')" :error="errors.offset" required />
      <fancy-input
        v-model.number="limit"
        type="number"
        :max="300"
        :label="$t('customerGroup.export.limit.label')"
        :error="errors.limit"
        required
      />
      <form-buttons
        :loading-submit="loading"
        :confirm-text="$t('customerGroup.action.startExport')"
        @cancel="closeModal"
      />
    </form>
  </modal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { saveAs } from 'file-saver';
import { useI18n } from 'vue-i18n';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Modal from '@/components/utils/Modal.vue';
import axios from '@/services/axios';
import FormButtons from '@/components/utils/forms/FormButtons.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import { CUSTOMER_GROUP_EXPORT_TYPES } from '@/constants';

export default {
  name: 'ExportCustomerGroupModal',
  components: {
    SimpleDropdown,
    FormButtons,
    Modal,
    FancyInput,
  },
  props: {
    customerGroup: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const errors = ref({});
    const state = ref({ open: false });
    const minNumber = ref('0');
    const maxNumber = ref('100');
    const limit = ref(100);
    const customCss = ref('* {\n'
      + '    box-sizing: border-box;\n'
      + '}\n'
      + 'body {\n'
      + '    display: flex;\n'
      + '    flex-wrap: wrap;\n'
      + '}\n'
      + '.qr-code {\n'
      + '    display: flex;\n'
      + '    width: 85.6mm;\n'
      + '    height: 53.98mm;\n'
      + '    border: 1px solid #000;\n'
      + '    align-items: center;\n'
      + '    padding: 1cm;\n'
      + '    margin: 0.5cm;\n'
      + '}\n'
      + '.meta {\n'
      + '    text-align: right;\n'
      + '}\n'
      + '.customer-group-description {\n'
      + '    border-bottom: 1px solid #CCC;\n'
      + '}\n'
      + '.customer-group-description, .customer-description {\n'
      + '    display: block;\n'
      + '    font-size: 0.8rem;\n'
      + '    color: #333;\n'
      + '}\n'
      + '.code img {\n'
      + '    width: 3cm;\n'
      + '    height: 3cm;\n'
      + '}');
    const exportType = ref(CUSTOMER_GROUP_EXPORT_TYPES.TYPE_QR);
    const entity = computed(() => store.state.modals.entity);

    const closeModal = () => {
      store.dispatch('modals/closeModal', 'exportQrCustomerGroup');
      errors.value = {};
    };

    watch(() => store.state.modals.exportQrCustomerGroup?.open, (val) => {
      state.value.open = val;
    });

    const startExport = () => {
      axios.post(`${window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL}${entity.value['@id']}/export?limit=${limit.value}&minNumber=${minNumber.value}&maxNumber=${maxNumber.value}`, {
        customCss: customCss.value,
      }, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, `qrCodes_${entity.value['@id']}.pdf`);
      });
    };

    const exportTypes = [];
    Object.entries(CUSTOMER_GROUP_EXPORT_TYPES).forEach((entry) => {
      const [key, value] = entry;
      exportTypes.push({ key, label: t(`customerGroup.export.type.${key}`), value });
    });

    return {
      closeModal,
      state,
      minNumber,
      maxNumber,
      limit,
      errors,
      startExport,
      exportTypes,
      exportType,
      customCss,
      CUSTOMER_GROUP_EXPORT_TYPES,
    };
  },
};
</script>
