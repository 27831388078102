<template>
  <div class="border-t border-gray-300 pt-3 mt-6">
    <simple-dropdown v-model="model.type" :options="identifierOptions" :label="$t('customerIdentifier.type.label')" />
    <fancy-input v-model="model.identifier" :label="$t('customerIdentifier.identifier.label')" class="mt-4" />
    <small>
      <a href="#" class="text-sm underline" @click="createRandom()">{{ $t('customerIdentifier.createRandom') }}</a>
    </small>
    &nbsp;&nbsp;
    <a
      href="#"
      class="text-sm underline"
      @click="$emit('click:delete', model)"
      v-text="$t('general.action.removeSth', [$t('customerIdentifier.label')])"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModel } from '@/hooks/model';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  name: 'CustomerIdentifierForm',
  components: {
    FancyInput,
    SimpleDropdown,
  },
  props: {
    modelValue: { type: Object, default: () => ({ type: 10, identifier: '' }) },
  },
  emits: ['click:delete'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModel(props, emit);
    const identifierOptions = ref([
      { key: '10', label: t('customerIdentifier.type.10'), value: 10 },
      { key: '20', label: t('customerIdentifier.type.20'), value: 20 },
    ]);

    return {
      model,
      identifierOptions,
    };
  },
  methods: {
    createRandom() {
      const arr = new Uint32Array(32);
      crypto.getRandomValues(arr);
      this.model.identifier = Buffer.from(arr)
        .toString('hex');
    },
  },
};
</script>
