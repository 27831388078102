<template>
  <modal :open="state.open" @close="closeModal">
    <div>
      <vue-qr :text="entity.identifier" />
    </div>
  </modal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import Modal from '@/components/utils/Modal.vue';
import axios from '@/services/axios';

export default {
  name: 'CustomerIdentifierQrModal',
  components: {
    Modal,
    VueQr,
  },
  setup() {
    const store = useStore();
    const state = ref({ open: false });
    const entity = computed(() => store.state.modals.entity);
    watch(() => store.state.modals.customerIdentifierQr?.open, (val) => {
      state.value.open = val;
    });
    const closeModal = () => {
      store.dispatch('modals/closeModal', 'customerIdentifierQr');
    };
    return {
      closeModal,
      state,
      entity,
    };
  },
  data() {
  },
  methods: {
  },
};
</script>
