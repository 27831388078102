<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editCustomer"
    :normalizer="saveNormalizer"
    :save-func="save"
    :options="{
      properties: {
        name: '',
        number: '',
        description: '',
        customerGroup: null,
        customerIdentifiers: [],
        balance: { currency: 'CHF', amount: 0 },
        sort: 500,
      },
      postUri: '/customers',
      label: $t('customer.label'),
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input v-model="properties.number" :label="$t('customer.number.label')" :error="errors.number" required />
    <fancy-input v-model="properties.description" :label="$t('general.field.description')" :error="errors.description" textarea />
    <price-form v-model="properties.balance" :label="$t('customer.balance.label')" />
    <fancy-input v-model="properties.sort" :label="$t('customer.sort.label')" type="number" />

    <multiselect
      v-model="properties.customerGroup"
      :options="async (q) => loadCustomerGroupOptions(q, properties.customerGroup)"
      :loading="loadingCustomerGroupOptions"
      :filter-results="false"
      :form-label="$t('customerGroup.label')"
      :error="errors.customerGroup"
      searchable
      required
    />

    <hr>
    <div class="flex items-center">
      <strong>
        {{ $t('customerIdentifier.label', 2) }}
      </strong>
      <button type="button" class="white small ml-4" @click="addCustomerIdentifier(properties)">
        +
      </button>
    </div>
    <customer-identifier-form
      v-for="identifier in properties.customerIdentifiers"
      :key="identifier['@id']"
      :model-value="identifier"
      @update:model-value="(e) => { identifier = e; }"
      @click:delete="removeCustomerIdentifier(properties, $event)"
    />
  </modal-form>
</template>

<script>
import { ref } from 'vue';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import FormButtons from '@/components/utils/forms/FormButtons.vue';
import CustomerIdentifierForm from '@/components/entities/customer/CustomerIdentifierForm.vue';
import axios from '@/services/axios';
import PriceForm from '@/components/shared/forms/PriceForm.vue';

export default {
  name: 'EditCustomerModal',
  components: {
    PriceForm,
    CustomerIdentifierForm,
    Multiselect,
    FormButtons,
    FancyInput,
    ModalForm,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const {
      loading: loadingCustomerGroupOptions,
      loadOptions: loadCustomerGroupOptions,
    } = useLoadOptions('/customer_groups', 'name');

    // Keep track of removed CustomerIdentifiers to be able to delete them separately
    const removedIdentifiers = ref([]);

    const addCustomerIdentifier = (properties) => {
      properties.customerIdentifiers.push({
        type: 10,
        identifier: '',
      });
    };

    const removeCustomerIdentifier = (properties, identifier) => {
      const idx = properties.customerIdentifiers.findIndex((id) => id.type === identifier.type && id.identifier === identifier.identifier);
      if (idx > -1) {
        removedIdentifiers.value.push(...properties.customerIdentifiers.splice(idx, 1));
      }
    };

    const saveNormalizer = (entity) => ({
      ...entity,
      customerIdentifiers: entity.customerIdentifiers.map((identifier) => ({
        ...identifier,
        '@id': undefined,
        id: identifier['@id'],
      })),
      balance: {
        ...entity.balance,
        id: entity.balance['@id'],
        '@id': undefined,
      },
    });

    const save = async (newValue, originalValue, errors, simpleSave) => {
      try {
        // Call normal save
        await simpleSave(newValue, originalValue);
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      // Go delete removedIdentifiers
      if (removedIdentifiers.value.length) {
        try {
          await Promise.allSettled(
            removedIdentifiers.value
              .map((identifier) => (identifier['@id'] ? axios.delete(identifier['@id']) : Promise.resolve(true))),
          );
          emit('update');
        } catch (err) {
          console.error(err, err.message, err.response);
        }
      }
    };

    return {
      loadCustomerGroupOptions,
      loadingCustomerGroupOptions,
      removedIdentifiers,
      save,
      saveNormalizer,
      addCustomerIdentifier,
      removeCustomerIdentifier,
    };
  },
};
</script>
