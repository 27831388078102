import { ref } from 'vue';

export default function useDataModal(entity) {
  const modal = ref(null);

  const openModal = (e) => {
    entity.value = e;
    modal.value.state.open = true;
  };

  return {
    modal,
    openModal,
  };
}
