<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="batchCustomer"
    :options="{
      properties: {
        rangeStart: null,
        amount: 1,
        balance: { currency: 'CHF', amount: 0 },
        identifiers: 10,
        customerGroup: null,
      },
      postUri: '/customers/batch_create',
      label: $t('customer.batch.createTitle'),
    }"
    @update="$emit('update')"
  >
    <multiselect
      v-model="properties.customerGroup"
      :options="async (q) => loadCustomerGroupOptions(q)"
      :loading="loadingCustomerGroupOptions"
      :filter-results="false"
      :form-label="$t('customerGroup.label')"
      :error="errors.customerGroup"
      searchable
      required
    />

    <customer-batch-form :model-value="properties" @update:modelValue="(e) => { properties = e; }" />
  </modal-form>
</template>
<script>
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import CustomerBatchForm from '@/components/entities/customer/CustomerBatchForm.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';

export default {
  name: 'CreateCustomerBatchModal',
  components: {
    CustomerBatchForm,
    Multiselect,
    ModalForm,
  },
  emits: ['update'],
  setup() {
    const {
      loading: loadingCustomerGroupOptions,
      loadOptions: loadCustomerGroupOptions,
    } = useLoadOptions('/customer_groups', 'name');

    return {
      loadCustomerGroupOptions,
      loadingCustomerGroupOptions,
    };
  },
};
</script>
