<template>
  <div>
    <router-tabs :links="links">
      <router-view class="pt-4" />
    </router-tabs>
  </div>
</template>
<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RouterTabs from '@/components/utils/RouterTabs.vue';

export default {
  name: 'CustomerMainPage',
  components: { RouterTabs },
  setup() {
    const { t } = useI18n();
    return {
      links: ref([
        { id: 'customer-index', path: '/customers', text: t('customer.label', 2) },
        {
          id: 'customer-groups', path: '/customers/groups', text: t('customerGroup.label', 2), onlyExact: true,
        },
      ]),
    };
  },
};
</script>
